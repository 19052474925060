import React, { useEffect, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import ReactPlayer from 'react-player/vimeo';
import {
  btnWrapper,
  coverVideo,
  gridPositioning,
  reactPlayer,
  showVideo,
  videoContainer,
  videoText,
} from './HeroVideo.module.scss';
import PlayButton from './PlayButton';

const HeroVideo = ({ title_svg, loop, vimeo }) => {
  const loopVideoRef = useRef();
  const [videoPlaying, setVideoPlaying] = useState(false);

  useEffect(() => {
    if (videoPlaying) loopVideoRef.current.pause();
    else loopVideoRef.current.play();
  }, [videoPlaying]);

  return (
    <div className={`${videoContainer} ${videoPlaying ? showVideo : ''}`}>
      <video
        className={`posRel ${gridPositioning} ${coverVideo}`}
        src={loop.localFile.publicURL}
        ref={loopVideoRef}
        volume={0}
        autoPlay
        muted
        loop
      />

      <div className={`pagePadding ${videoText} ${gridPositioning}`}>
        <SVG src={title_svg.localFile.publicURL} />
      </div>

      <div className={`${btnWrapper} ${gridPositioning}`}>
        <PlayButton
          color={'Green'}
          // className={`${playBtn}`}
          // brushClass={`${brush}`}
          onClick={() => setVideoPlaying(true)}
          isHome={true}
        />
      </div>

      <ReactPlayer
        className={`${reactPlayer}`}
        width="100%"
        height="100%"
        url={vimeo}
        controls
        playsinline // Prevent Vimeo from automatically entering fullscreen
        playing={videoPlaying}
        onEnded={() => setVideoPlaying(false)}
        onPause={() => setVideoPlaying(false)}
      />
    </div>
  );
};

export default HeroVideo;
