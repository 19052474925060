import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React from 'react';
import {
  backgroundImg,
  imgContainer,
  ledeText,
  multiply,
  previewImg,
  storyGrid,
  storyItem,
  titleText,
  year,
} from './StoryPreview.module.scss';

const StoryPreview = ({ chapter, texture, color }) => {
  const { title, war, lede, slug, hero, start_date, end_date } = chapter;
  return (
    <li className={`${storyItem} mb112`}>
      <Link
        to={`/${slug}`}
        key={slug}
        className={`${storyGrid} varColor${color}`}
      >
        <GatsbyImage
          className={`${backgroundImg} varBgColor`}
          imgClassName={`${multiply}`}
          alt=""
          image={getImage(texture)}
        />
        <p className={`h4 date ${year}`} aria-hidden={true}>
          {start_date}
          {start_date !== end_date && ` – ${end_date}`}
        </p>
        <div className={`pagePadding ${titleText}`}>
          <p className={`pt24 h4`} aria-hidden={true}>
            {war}
          </p>
          <h2 className={`mt8 h3`}>
            <span className={`srOnly`}>
              {`${war}, ${start_date}${
                start_date !== end_date
                  ? ` to ${end_date ? end_date : 'present'}`
                  : ''
              }.`}
            </span>
            {title}
          </h2>
        </div>
        <GatsbyImage
          alt={hero.alternativeText}
          image={getImage(hero.localFile)}
          className={imgContainer}
          imgClassName={previewImg}
        />
        <div className={`mt16 pagePadding ${ledeText}`}>{parse(lede)}</div>
      </Link>
    </li>
  );
};

export default StoryPreview;
