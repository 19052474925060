import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Layout';
import parse from 'html-react-parser';
import StoryPreview from '../components/StoryPreview';
import HeroVideo from '../components/HeroVideo';
import {
  descContainer,
  transcriptDesc,
  desc,
  list,
  storySection,
} from './Home.module.scss';
import getAccentColor from '../utils/getAccentColor';
import TranscriptButton from '../components/TranscriptButton';

const IndexPage = ({ data }) => {
  const {
    site_title,
    title_svg,
    description,
    intro_loop_file,
    intro_vimeo,
    intro_transcript,
  } = data.strapiHomepage;
  const textureImg = data.allFile.nodes[0].childImageSharp.gatsbyImageData;

  return (
    <Layout>
      <h1 className={`srOnly`}>{site_title}</h1>

      <HeroVideo
        title_svg={title_svg}
        vimeo={intro_vimeo}
        loop={intro_loop_file}
        transcript={intro_transcript}
      />

      <div className={`${descContainer}`}>
        <div className={`${transcriptDesc}`}>
          <TranscriptButton
            transcript={intro_transcript}
            color={getAccentColor(0)}
            isHome={true}
          />
          <div className={`pt16 ${desc}`}>{parse(description)}</div>
        </div>
      </div>

      <div className={`${storySection}`}>
        <h2 className={`textCenter pagePadding`}>Stories</h2>

        <ul className={`mt32 ${list}`}>
          {data.allStrapiStories.nodes.map((chapter, index) => (
            <StoryPreview
              chapter={chapter}
              key={index}
              color={getAccentColor(index)}
              texture={textureImg}
            />
          ))}
        </ul>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    strapiHomepage {
      site_title
      description
      title_svg {
        alternativeText
        localFile {
          publicURL
        }
      }
      intro_loop
      intro_loop_file {
        alternativeText
        caption
        localFile {
          publicURL
        }
      }
      intro_vimeo
      intro_transcript
    }
    allStrapiStories(sort: { fields: [order], order: ASC }) {
      nodes {
        slug
        title
        war
        start_date
        end_date
        lede
        hero {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                quality: 100
              )
            }
          }
        }
      }
    }
    allFile(filter: { name: { eq: "white-grunge-textures-1" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`;

export default IndexPage;
